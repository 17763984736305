import * as React from 'react'
import { To } from 'react-router-dom'
import {
  DsDrawer,
  DsIconButton,
  DsRemixIcon,
  DsStack,
  DsTab,
  DsTabs,
  DsTypography
} from '@am92/react-design-system'

import LanguageSelector from '~/src/Components/LanguageSelector'

import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from '../../../MainLayout.PageConstants/TYPES'
import { TNavLink } from '../Header.Types'
import LogoWithNav from './LogoWithNav'

export interface IActionsMobileProps {
  PAGE_CONSTANTS: T_MAIN_LAYOUT_PAGE_CONSTANTS
  navLinks: TNavLink[]
  currentPath: string | false
  navigateTo: (pathname: To) => void
}

const ActionsMobile: React.FC<IActionsMobileProps> = props => {
  const { PAGE_CONSTANTS, navLinks, currentPath, navigateTo } = props

  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const setDrawerClose = () => setDrawerOpen(false)

  function handleTabChange(event: React.SyntheticEvent, pathname: To) {
    if (pathname.toString().includes('https://')) {
      window.open(pathname.toString(), '_blank')
    } else {
      navigateTo(pathname)
      setTimeout(() => {
        setDrawerClose()
      }, 100)
    }
  }

  return (
    <>
      <DsStack direction='row' alignItems='center'>
        <DsIconButton onClick={() => setDrawerOpen(true)}>
          <DsRemixIcon className='ri-menu-line' />
        </DsIconButton>
      </DsStack>

      <DsDrawer
        anchor='left'
        open={drawerOpen}
        onClose={setDrawerClose}
        PaperProps={{
          sx: {
            maxWidth: '300px',
            width: '100%'
          }
        }}
      >
        <DsStack
          direction='column'
          spacing='var(--ds-spacing-bitterCold)'
          sx={{ height: '100%' }}
        >
          <DsStack
            direction='row'
            justifyContent='space-between'
            alignItems='center'
            spacing='var(--ds-spacing-bitterCold)'
            sx={{
              px: 'var(--ds-spacing-bitterCold)',
              borderBottom: '1px solid var(--ds-colour-strokeDefault)'
            }}
          >
            <LogoWithNav onClick={setDrawerClose} />
            <LanguageSelector />
          </DsStack>

          <DsStack direction='column' spacing='var(--ds-spacing-glacial)'>
            <DsTypography
              variant='subheadingSemiboldLarge'
              sx={{ px: 'var(--ds-spacing-bitterCold)' }}
            >
              {PAGE_CONSTANTS.NAVIGATION_HEADER}
            </DsTypography>

            <DsTabs
              value={currentPath}
              onChange={handleTabChange}
              orientation='vertical'
              TabIndicatorProps={{ sx: { left: 0 } }}
              sx={{
                '.MuiTabs-flexContainer': {
                  '.MuiButtonBase-root': {
                    borderColor: 'transparent',
                    p: 'var(--ds-spacing-bitterCold)',
                    justifyContent: 'flex-start'
                  }
                },
                '.MuiTabs-indicator': {
                  width: '4px'
                }
              }}
            >
              {navLinks.map((link, index) => (
                <DsTab
                  key={index}
                  value={link.value}
                  label={link.label}
                  sx={{
                    alignItems: 'flex-start',
                    pr: 'var(--ds-spacing-plasma)',
                    mt: index ? 'var(--ds-spacing-frostbite)' : undefined
                  }}
                />
              ))}
            </DsTabs>
          </DsStack>
        </DsStack>
      </DsDrawer>
    </>
  )
}

export default ActionsMobile
