import traceActionsCreator from '../traceActionsCreator'

import { I_SUBSCRIPTION } from '~/src/Entities/Subscription/Subscription.Types'

// export const sampleAction = createAction('Subscription/sample')

export const fetchSubscriptionsServiceName = 'Subscription/fetchSubscriptions'
export const fetchSubscriptionsTraceActions = traceActionsCreator<
  I_SUBSCRIPTION[]
>(fetchSubscriptionsServiceName)
