const LOGO_IMAGE = [
  {
    src: new URL('~/public/assets/images/logo.png?as=avif', import.meta.url)
      .href,
    alt: 'Logo',
    as: 'image/avif'
  },
  {
    src: new URL('~/public/assets/images/logo.png?as=webp', import.meta.url)
      .href,
    alt: 'Logo',
    as: 'image/webp'
  },
  {
    src: new URL('~/public/assets/images/logo.png', import.meta.url).href,
    alt: 'Logo',
    as: 'image/png'
  }
]

export default LOGO_IMAGE
