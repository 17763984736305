import { NavigateOptions, To } from 'react-router-dom'

type AppRouteItem = {
  pathname: To
  options?: NavigateOptions
}

const APP_ROUTES = {
  LANDING: {
    pathname: '/'
  } as AppRouteItem,

  TERMS_AND_CONDITIONS: {
    pathname: '/terms-and-conditions'
  } as AppRouteItem,

  PRIVACY_POLICY: {
    pathname: '/privacy-policy'
  } as AppRouteItem,

  FAQ: {
    pathname: '/faq'
  } as AppRouteItem,

  PRICING: {
    pathname: '/pricing'
  } as AppRouteItem,

  CONTACT_US: {
    pathname: '/contact-us'
  } as AppRouteItem,

  ANY: {
    pathname: '*'
  } as AppRouteItem
}

export default APP_ROUTES
