import React from 'react'
import {
  DsStack,
  IwithBreakpoints,
  withBreakpoints
} from '@am92/react-design-system'

import withRouter, { IWithRouterProps } from '~/src/Hocs/withRouter'

import ActionsDesktop from './Components/ActionsDesktop'
import ActionsMobile from './Components/ActionsMobile'
import LogoWithNav from './Components/LogoWithNav'

import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from '../../MainLayout.PageConstants/TYPES'
import { TNavLink } from './Header.Types'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'
import { APP_GET_STARTED_URL } from '~/src/Configurations/env'

interface IHeaderProps extends IwithBreakpoints, IWithRouterProps {
  PAGE_CONSTANTS: T_MAIN_LAYOUT_PAGE_CONSTANTS
}

const Header: React.FC<IHeaderProps> = props => {
  const { PAGE_CONSTANTS, breakpoints, location, navigateTo } = props

  const navLinks: TNavLink[] = [
    {
      label: PAGE_CONSTANTS.LINK_HOME_CTA_TEXT,
      value: APP_ROUTES.LANDING.pathname
    },
    {
      label: PAGE_CONSTANTS.LINK_FAQ_CTA_TEXT,
      value: APP_ROUTES.FAQ.pathname
    },
    {
      label: PAGE_CONSTANTS.LINK_PRICING_CTA_TEXT,
      value: APP_ROUTES.PRICING.pathname
    },
    {
      label: PAGE_CONSTANTS.LINK_CONTACT_US_CTA_TEXT,
      value: APP_ROUTES.CONTACT_US.pathname
    },
    {
      label: PAGE_CONSTANTS.LINK_LOGIN_CTA_TEXT,
      value: APP_GET_STARTED_URL
    }
  ]

  const currentPath = navLinks.reduce((value, link) => {
    return location.pathname === link.value ? link.value : value
  }, '')

  const isMobile = breakpoints.xs || breakpoints.sm

  return (
    <DsStack
      direction='row'
      justifyContent='space-between'
      sx={theme => ({
        px: {
          xs: 'var(--ds-spacing-bitterCold)',
          lg: 'var(--ds-spacing-pleasant)'
        },
        backgroundColor: 'var(--ds-colour-surfaceBackground)',
        position: 'sticky',
        top: 0,
        zIndex: theme.zIndex.appBar,
        borderBottom: '1px solid var(--ds-colour-strokeDefault)'
      })}
    >
      <LogoWithNav />

      {isMobile ? (
        <ActionsMobile
          PAGE_CONSTANTS={PAGE_CONSTANTS}
          navLinks={navLinks}
          currentPath={currentPath || false}
          navigateTo={navigateTo}
        />
      ) : (
        <ActionsDesktop
          navLinks={navLinks}
          currentPath={currentPath || false}
          navigateTo={navigateTo}
        />
      )}
    </DsStack>
  )
}

export default withRouter(withBreakpoints(Header))
