import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'language'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getSelectedLanguageSelector = createSelector(
  select,
  languageReducer => languageReducer.selectedLanguage
)
