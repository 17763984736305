const DE_FLAG_IMAGE = [
  {
    src: new URL(
      '~/public/assets/images/languages/de.png?as=webp',
      import.meta.url
    ).href,
    alt: 'DE',
    as: 'image/webp'
  },
  {
    src: new URL('~/public/assets/images/languages/de.png', import.meta.url)
      .href,
    alt: 'DE',
    as: 'image/png'
  }
]

export default DE_FLAG_IMAGE
