import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from './TYPES'

const DE_MAIN_LAYOUT_PAGE_CONSTANTS: T_MAIN_LAYOUT_PAGE_CONSTANTS = {
  LINK_PRIVACY_POLICY_CTA_TEXT: 'Datenschutz-Bestimmungen',
  LINK_TERMS_CTA_TEXT: 'Allgemeine Geschäftsbedingungen',
  NAVIGATION_HEADER: 'Navigationen',
  LINK_HOME_CTA_TEXT: 'Startseite',
  LINK_FAQ_CTA_TEXT: 'FAQ',
  LINK_PRICING_CTA_TEXT: 'Preise',
  LINK_CONTACT_US_CTA_TEXT: 'Kontakt',
  LINK_LOGIN_CTA_TEXT: 'Anmeldung'
}

export default DE_MAIN_LAYOUT_PAGE_CONSTANTS
