type Primitive = string | number | bigint | boolean | undefined | symbol

export type ObjectPath<T, Prefix = ''> = {
  [K in keyof T]: T[K] extends Primitive | Array<any>
    ? `${string & Prefix}${string & K}`
    :
        | `${string & Prefix}${string & K}`
        | ObjectPath<T[K], `${string & Prefix}${string & K}.`>
}[keyof T]

export default function objectPathFactory<T, R = string>(): (
  path: ObjectPath<T>
) => R {
  // @ts-ignore
  return (path: ObjectPath<T>) => path as unknown as R
}
