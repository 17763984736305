import React from 'react'
import { createBrowserRouter, RouteObject } from 'react-router-dom'

import ErrorBoundary from '../Layouts/ErrorBoundary'
import MainLayout from '~/src/Layouts/Main.Layout'

import NotFoundLoader from '../Pages/NotFound/NotFound.Loader'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

const LandingPage = React.lazy(() => import('~/src/Pages/Landing/Landing.Page'))

const TermsAndConditionsPage = React.lazy(
  () => import('~/src/Pages/TermsAndConditions/TermsAndConditions.Page')
)

const PrivacyPolicyPage = React.lazy(
  () => import('~/src/Pages/PrivacyPolicy/PrivacyPolicy.Page')
)

const FAQPage = React.lazy(() => import('~/src/Pages/FAQ/FAQ.Page'))

const PricingPage = React.lazy(() => import('~/src/Pages/Pricing/Pricing.Page'))

const ContactUsPage = React.lazy(
  () => import('~/src/Pages/ContactUs/ContactUs.Page')
)

const NotFoundPage = React.lazy(
  () => import('~/src/Pages/NotFound/NotFound.Page')
)

const routeObj: RouteObject[] = [
  {
    element: <MainLayout />,
    children: [
      {
        path: APP_ROUTES.LANDING.pathname,
        element: <LandingPage />
      } as RouteObject,
      {
        path: APP_ROUTES.TERMS_AND_CONDITIONS.pathname,
        element: <TermsAndConditionsPage />
      } as RouteObject,
      {
        path: APP_ROUTES.PRIVACY_POLICY.pathname,
        element: <PrivacyPolicyPage />
      } as RouteObject,
      {
        path: APP_ROUTES.FAQ.pathname,
        element: <FAQPage />
      } as RouteObject,
      {
        path: APP_ROUTES.PRICING.pathname,
        element: <PricingPage />
      } as RouteObject,
      {
        path: APP_ROUTES.CONTACT_US.pathname,
        element: <ContactUsPage />
      } as RouteObject
    ]
  } as RouteObject,
  {
    path: APP_ROUTES.ANY.pathname,
    loader: NotFoundLoader,
    errorElement: <ErrorBoundary />,
    element: <NotFoundPage />
  } as RouteObject
]

const getAppRouter = () => createBrowserRouter(routeObj)

export default getAppRouter
