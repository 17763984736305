import React from 'react'
import {
  DsImage,
  DsImageProps,
  DsInputBase,
  DsInputBaseProps,
  DsMenuItem,
  DsRemixIcon,
  DsRemixIconProps,
  DsSelect,
  DsStack,
  DsTypography
} from '@am92/react-design-system'

import getWithPageConstants, {
  IWithPageConstantsProps
} from '~/src/Hocs/withPageConstants'

import {
  LANGUAGE_FLAG_MAP,
  LANGUAGE_MAP,
  LANGUAGE_SELECT_OPTIONS,
  T_LANGUAGE_CODES
} from '../Constants/LANGUAGE'

const IconComponent = (props: Omit<DsRemixIconProps, 'ref'>) => {
  return (
    <DsRemixIcon
      {...props}
      className={`${props.className} ri-arrow-down-s-fill`}
    />
  )
}

interface ILanguageSelectorProps extends IWithPageConstantsProps<object> {
  selectedValueImageProps?: DsImageProps
  InputProps?: DsInputBaseProps
}

const LanguageSelector = ({
  selectedLanguage,
  selectedValueImageProps,
  PAGE_CONSTANTS,
  setLanguage,
  InputProps,
  ...selectProps
}: ILanguageSelectorProps) => {
  const handleLanguageChange = (event: any) => {
    const { value } = event.target
    setLanguage(value as T_LANGUAGE_CODES)
  }

  const renderLanguageOptions = () => {
    return LANGUAGE_SELECT_OPTIONS.map((languageOption, index) => (
      <DsMenuItem value={languageOption.value} key={index}>
        {renderSelectedLanguage(languageOption.value, false)}
      </DsMenuItem>
    ))
  }

  const renderSelectedLanguage = (value: unknown, condense: boolean = true) => {
    const languageSrcset = LANGUAGE_FLAG_MAP[value as T_LANGUAGE_CODES]
    const languageLabel = LANGUAGE_MAP[value as T_LANGUAGE_CODES]
    return (
      <DsStack
        direction='row'
        alignItems='center'
        spacing='var(--ds-spacing-quickFreeze)'
      >
        <DsImage
          {...selectedValueImageProps}
          srcSet={languageSrcset}
          alt={`${languageLabel} Flag`}
          style={{ display: 'block', ...selectedValueImageProps?.style }}
          WrapperProps={{ sx: { width: 'auto' } }}
        />
        <DsTypography
          variant='bodyRegularSmall'
          sx={condense ? { display: { xs: 'none', md: 'block' } } : undefined}
        >
          {languageLabel}
        </DsTypography>
      </DsStack>
    )
  }

  return (
    <DsSelect
      value={selectedLanguage}
      renderValue={renderSelectedLanguage}
      onChange={handleLanguageChange}
      IconComponent={IconComponent}
      input={
        <DsInputBase
          {...InputProps}
          sx={{
            borderRadius: '32px',
            px: 'var(--ds-spacing-frostbite)',
            ...InputProps?.sx
          }}
        />
      }
      {...selectProps}
      sx={{
        '.MuiSelect-select': {
          pr: 'var(--ds-spacing-mild) !important'
        }
      }}
    >
      {renderLanguageOptions()}
    </DsSelect>
  )
}

const withPageConstants = getWithPageConstants<object>({})

export default withPageConstants(LanguageSelector)
