const HU_FLAG_IMAGE = [
  {
    src: new URL(
      '~/public/assets/images/languages/hu.png?as=webp',
      import.meta.url
    ).href,
    alt: 'HU',
    as: 'image/webp'
  },
  {
    src: new URL('~/public/assets/images/languages/hu.png', import.meta.url)
      .href,
    alt: 'HU',
    as: 'image/png'
  }
]

export default HU_FLAG_IMAGE
