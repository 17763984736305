import './index.scss'

import React from 'react'
import { Outlet, ScrollRestoration } from 'react-router-dom'

import getWithPageConstants, {
  IWithPageConstantsProps
} from '~/src/Hocs/withPageConstants'

import Footer from './Components/Footer'
import Header from './Components/Header'

import MAIN_LAYOUT_PAGE_CONSTANTS from './MainLayout.PageConstants'
import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from './MainLayout.PageConstants/TYPES'

interface IMainLayoutProps
  extends IWithPageConstantsProps<T_MAIN_LAYOUT_PAGE_CONSTANTS> {}

const MainLayout: React.FC<IMainLayoutProps> = props => {
  const { PAGE_CONSTANTS } = props

  return (
    <>
      <Header PAGE_CONSTANTS={PAGE_CONSTANTS} />
      <Outlet />
      <Footer PAGE_CONSTANTS={PAGE_CONSTANTS} />
      <ScrollRestoration />
    </>
  )
}

const withPageConstants = getWithPageConstants<T_MAIN_LAYOUT_PAGE_CONSTANTS>(
  MAIN_LAYOUT_PAGE_CONSTANTS
)

export default withPageConstants(MainLayout)
