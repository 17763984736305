import {
  DsDatePickerLocaleDeDE,
  DsDatePickerLocaleFrFR,
  DsDatePickerLocaleHuHU,
  DsImageProps
} from '@am92/react-design-system'

import DE_FLAG_IMAGE from '~/src/Assets/LANGUAGE/DE_FLAG_IMAGE'
import EN_FLAG_IMAGE from '~/src/Assets/LANGUAGE/EN_FLAG_IMAGE'
import FR_FLAG_IMAGE from '~/src/Assets/LANGUAGE/FR_FLAG_IMAGE'
import HU_FLAG_IMAGE from '~/src/Assets/LANGUAGE/HU_FLAG_IMAGE'

export type T_LANGUAGES = 'magyar' | 'English' | 'français' | 'Deutsch'
export type T_LANGUAGE_CODES = 'hu' | 'en' | 'fr' | 'de'
export const LANGUAGE_CODES: T_LANGUAGE_CODES[] = ['hu', 'en', 'fr', 'de']

export type T_LANGUAGE_MAP = { [key in T_LANGUAGE_CODES]: T_LANGUAGES }
export type T_GENERIC_LANGUAGE_MAP<T> = {
  [key in T_LANGUAGE_CODES]: T
}

export type T_LANGUAGE_SELECT_OPTION = {
  label: T_LANGUAGES
  value: T_LANGUAGE_CODES
}

export const LANGUAGE_MAP: T_LANGUAGE_MAP = {
  hu: 'magyar',
  en: 'English',
  fr: 'français',
  de: 'Deutsch'
}

export const LANGUAGE_FLAG_MAP: T_GENERIC_LANGUAGE_MAP<DsImageProps['srcSet']> =
  {
    hu: HU_FLAG_IMAGE,
    en: EN_FLAG_IMAGE,
    fr: FR_FLAG_IMAGE,
    de: DE_FLAG_IMAGE
  }

export const LANGUAGE_SELECT_OPTIONS: T_LANGUAGE_SELECT_OPTION[] = Object.keys(
  LANGUAGE_MAP
).map(value => ({
  label: LANGUAGE_MAP[value as T_LANGUAGE_CODES] as T_LANGUAGES,
  value: value as T_LANGUAGE_CODES
}))

export type TLocale = typeof DsDatePickerLocaleHuHU

export const LOCALE_MAP: T_GENERIC_LANGUAGE_MAP<TLocale[]> = {
  hu: [DsDatePickerLocaleHuHU],
  en: [],
  fr: [DsDatePickerLocaleFrFR],
  de: [DsDatePickerLocaleDeDE]
}
