import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from './TYPES'

const EN_MAIN_LAYOUT_PAGE_CONSTANTS: T_MAIN_LAYOUT_PAGE_CONSTANTS = {
  LINK_PRIVACY_POLICY_CTA_TEXT: 'Privacy Policy',
  LINK_TERMS_CTA_TEXT: 'Terms and Conditions',
  NAVIGATION_HEADER: 'Navigations',
  LINK_HOME_CTA_TEXT: 'Home',
  LINK_FAQ_CTA_TEXT: 'FAQ',
  LINK_PRICING_CTA_TEXT: 'Pricing',
  LINK_CONTACT_US_CTA_TEXT: 'Contact Us',
  LINK_LOGIN_CTA_TEXT: 'Login'
}

export default EN_MAIN_LAYOUT_PAGE_CONSTANTS
