import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Subtract } from 'utility-types'

import { setLanguageAction } from '../Redux/Language/Actions'
import { getSelectedLanguageSelector } from '../Redux/Language/Selectors'

import { T_GENERIC_LANGUAGE_MAP, T_LANGUAGE_CODES } from '../Constants/LANGUAGE'

/**
 * Basic Interface to extend in components wrapping the below HOC.
 *
 * @export
 * @interface IWithPageConstantsProps
 * @template T
 */
export interface IWithPageConstantsProps<T> {
  PAGE_CONSTANTS: T
  selectedLanguage: T_LANGUAGE_CODES
  setLanguage: (Language: T_LANGUAGE_CODES) => void
}

/**
 *
 *
 * @export
 * @template T
 * @param {Partial<T_GENERIC_LANGUAGE_MAP<T>>} PAGE_CONSTANTS_MAP
 * @return {*} an hoc for the component
 */

export default function getWithPageConstants<T>(
  PAGE_CONSTANTS_MAP: Partial<T_GENERIC_LANGUAGE_MAP<T>>
) {
  return function withPageConstants<P extends IWithPageConstantsProps<T>>(
    Component: React.ComponentType<P>
  ) {
    return function withPageConstantsComponent(
      props: Subtract<P, IWithPageConstantsProps<T>>
    ) {
      const selectedLanguage = useSelector(getSelectedLanguageSelector)
      const dispatch = useDispatch()
      const setLanguage = (language: T_LANGUAGE_CODES) =>
        dispatch(setLanguageAction(language))

      const PAGE_CONSTANTS = PAGE_CONSTANTS_MAP[selectedLanguage]

      return (
        <Component
          {...(props as P)}
          PAGE_CONSTANTS={PAGE_CONSTANTS}
          selectedLanguage={selectedLanguage}
          setLanguage={setLanguage}
        />
      )
    }
  }
}
