import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from './TYPES'

const FR_MAIN_LAYOUT_PAGE_CONSTANTS: T_MAIN_LAYOUT_PAGE_CONSTANTS = {
  LINK_PRIVACY_POLICY_CTA_TEXT: 'Politique de confidentialité',
  LINK_TERMS_CTA_TEXT: 'Conditions générales',
  NAVIGATION_HEADER: 'Navigations',
  LINK_HOME_CTA_TEXT: 'Accueil',
  LINK_FAQ_CTA_TEXT: 'FAQ',
  LINK_PRICING_CTA_TEXT: 'Tarification',
  LINK_CONTACT_US_CTA_TEXT: 'Contactez-nous',
  LINK_LOGIN_CTA_TEXT: 'Connexion'
}

export default FR_MAIN_LAYOUT_PAGE_CONSTANTS
