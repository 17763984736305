import { createSelector } from '@reduxjs/toolkit'

import { TAppStore } from '~/src/Configurations/AppStore'

export const SLICE_NAME = 'subscription'

const select = (state: TAppStore) => state[SLICE_NAME]

export const getSubscriptionReducer = select

export const getSubscriptionListSubscriptionSelector = createSelector(
  select,
  reducer => reducer.subscriptions
)
