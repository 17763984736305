import { LANGUAGE_CODES, T_LANGUAGE_CODES } from '~/src/Constants/LANGUAGE'

export type T_LANGUAGE_REDUCER = {
  selectedLanguage: T_LANGUAGE_CODES
}

export const INITIAL_STATE: T_LANGUAGE_REDUCER = {
  selectedLanguage: _getBrowserLanguage()
}

function _getBrowserLanguage(): T_LANGUAGE_CODES {
  const browserLanguage = (window.navigator?.language || '').split('-')[0] || ''

  if (LANGUAGE_CODES.includes(browserLanguage as T_LANGUAGE_CODES)) {
    return browserLanguage as T_LANGUAGE_CODES
  }

  return 'en'
}
