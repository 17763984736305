import {
  ActionReducerMapBuilder,
  createSlice,
  CreateSliceOptions
} from '@reduxjs/toolkit'

import { setLanguageAction } from './Actions'
import { SLICE_NAME } from './Selectors'
import { INITIAL_STATE, T_LANGUAGE_REDUCER } from './TYPES'

const sliceOptions: CreateSliceOptions = {
  name: SLICE_NAME,
  initialState: INITIAL_STATE,
  reducers: {},
  extraReducers: (
    builder: ActionReducerMapBuilder<T_LANGUAGE_REDUCER>
  ): void => {
    builder.addCase(setLanguageAction, (state, { payload }) => {
      state.selectedLanguage = payload || INITIAL_STATE.selectedLanguage
    })
  }
}

const slice = createSlice(sliceOptions)

export default slice.reducer
