import React from 'react'
import { Link } from 'react-router-dom'
import { DsStack, DsTypography } from '@am92/react-design-system'

import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from '../MainLayout.PageConstants/TYPES'

import APP_ROUTES from '~/src/Constants/APP_ROUTES'

interface IFooterProps {
  PAGE_CONSTANTS: T_MAIN_LAYOUT_PAGE_CONSTANTS
}

const Footer: React.FC<IFooterProps> = props => {
  const { PAGE_CONSTANTS } = props

  const links = [
    {
      ctaText: PAGE_CONSTANTS.LINK_TERMS_CTA_TEXT,
      to: APP_ROUTES.TERMS_AND_CONDITIONS.pathname
    },
    {
      ctaText: PAGE_CONSTANTS.LINK_PRIVACY_POLICY_CTA_TEXT,
      to: APP_ROUTES.PRIVACY_POLICY.pathname
    }
  ]

  return (
    <DsStack
      direction={{ xs: 'column-reverse', md: 'row' }}
      alignItems={{ xs: undefined, md: 'center' }}
      spacing={{
        xs: 'var(--ds-spacing-bitterCold)',
        md: 'var(--ds-spacing-glacial)'
      }}
      sx={{
        backgroundColor: 'var(--ds-colour-surfaceSecondary)',
        borderTop: '1px solid var(--ds-colour-strokeDefault)',
        py: {
          xs: 'var(--ds-spacing-bitterCold)',
          md: 'var(--ds-spacing-pleasant)'
        },
        px: {
          xs: 'var(--ds-spacing-bitterCold)',
          md: 'var(--ds-spacing-pleasant)'
        }
      }}
    >
      <DsTypography
        variant='supportRegularMetadata'
        textAlign={{ xs: 'center', md: 'end' }}
      >
        © {new Date().getFullYear()} M/s. Adonis Consultancy
      </DsTypography>

      <DsStack
        direction={{ xs: 'row', md: 'row' }}
        justifyContent={{ xs: 'space-around', md: 'flex-end' }}
        spacing={{
          xs: 'var(--ds-spacing-glacial)',
          md: 'var(--ds-spacing-bitterCold)'
        }}
        sx={{ flex: 1 }}
      >
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            style={{
              textDecoration: 'none',
              color: 'var(--ds-colour-typoPrimary)'
            }}
          >
            <DsTypography variant='bodyRegularMedium'>
              {link.ctaText}
            </DsTypography>
          </Link>
        ))}
      </DsStack>
    </DsStack>
  )
}

export default Footer
