import * as React from 'react'
import { To } from 'react-router-dom'
import { DsStack, DsTab, DsTabs } from '@am92/react-design-system'

import LanguageSelector from '~/src/Components/LanguageSelector'

import { TNavLink } from '../Header.Types'

export interface IActionsDesktopProps {
  navLinks: TNavLink[]
  currentPath: string | false
  navigateTo: (pathname: To) => void
}

const ActionsDesktop: React.FC<IActionsDesktopProps> = props => {
  const { navLinks, currentPath, navigateTo } = props

  return (
    <DsStack
      direction='row'
      spacing={{
        xs: 'var(--ds-spacing-frostbite)',
        md: 'var(--ds-spacing-warm)'
      }}
    >
      <DsTabs
        value={currentPath}
        onChange={(event: React.SyntheticEvent, pathname: To) => {
          if (pathname.toString().includes('https://')) {
            window.open(pathname.toString(), '_blank')
          } else {
            navigateTo(pathname)
          }
        }}
        sx={{
          '.MuiTabs-flexContainer': {
            height: '100%',
            '.MuiButtonBase-root': {
              minWidth: { xs: '56px', md: '74px' },
              borderColor: 'transparent',
              '&:nth-of-type(n+2)': {
                ml: {
                  xs: 'var(--ds-spacing-zero)',
                  md: 'var(--ds-spacing-bitterCold)'
                }
              }
            }
          },
          '.MuiTabs-indicator': {
            height: '4px'
          }
        }}
      >
        {navLinks.map((link, index) => (
          <DsTab key={index} value={link.value} label={link.label} />
        ))}
      </DsTabs>
      <DsStack direction='row' alignItems='center'>
        <LanguageSelector />
      </DsStack>
    </DsStack>
  )
}

export default ActionsDesktop
