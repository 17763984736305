import { DsPalette, SupportedColorScheme } from '@am92/react-design-system'

export type T_THEME_REDUCER = {
  defaultMode: 'light' | 'dark' | 'system'
  scheme: SupportedColorScheme
  palette: DsPalette
  fontFamily: string
}

export const DEFAULT_THEME: T_THEME_REDUCER = {
  defaultMode: 'light',
  scheme: 'light',
  palette: {
    primary: '#360F7C',
    secondary100: '#591FC2',
    secondary80: '#591FC2',
    secondary60: '#591FC2',
    secondary40: '#591FC2',
    secondary20: '#591FC2'
  },
  fontFamily: 'Noto'
}

export const INITIAL_STATE: T_THEME_REDUCER = DEFAULT_THEME
