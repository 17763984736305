import DE_MAIN_LAYOUT_PAGE_CONSTANTS from './de'
import EN_MAIN_LAYOUT_PAGE_CONSTANTS from './en'
import FR_MAIN_LAYOUT_PAGE_CONSTANTS from './fr'
import HU_MAIN_LAYOUT_PAGE_CONSTANTS from './hu'
import { T_MAIN_LAYOUT_PAGE_CONSTANTS } from './TYPES'

import { T_GENERIC_LANGUAGE_MAP } from '~/src/Constants/LANGUAGE'

import objectPathFactory from '~/src/Lib/objectPathFactory'

const MAIN_LAYOUT_PAGE_CONSTANTS: T_GENERIC_LANGUAGE_MAP<T_MAIN_LAYOUT_PAGE_CONSTANTS> =
  {
    hu: HU_MAIN_LAYOUT_PAGE_CONSTANTS,
    en: EN_MAIN_LAYOUT_PAGE_CONSTANTS,
    fr: FR_MAIN_LAYOUT_PAGE_CONSTANTS,
    de: DE_MAIN_LAYOUT_PAGE_CONSTANTS
  }

export default MAIN_LAYOUT_PAGE_CONSTANTS

export const MAIN_LAYOUT_PAGE_CONSTANTS_PATH =
  objectPathFactory<T_MAIN_LAYOUT_PAGE_CONSTANTS>()
